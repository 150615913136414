import { useContext, useState, useEffect, ChangeEvent } from "react";
import { ToastContainer } from "react-toastify";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Pagination, Stack } from "@mui/material";
import ExcelExport from "../../../../Excel/ExcelExport";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";

interface PLData {
  _id: string;
  provider: string;
  reportDate: string;
  totalGames: number;
  totalBet: number;
  totalWin: number;
  ourTotalWin: number;
  ourTotalBet: number;
  ourTotalCommission:number;
}

export default function ProfitandLoss() {
  const [selectVendor, setSelectVendor] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [plData, setPlData] = useState<Array<PLData>>([]);
  const [searchUserId, setSearchUserId] = useState<string>("");
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchUserMob, setSearchUserMob] = useState<string>("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const Navigate = useNavigate();
  const { User } = useContext(User_Context);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);



  const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserId(event.target.value);
  };
  const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(event.target.value);
  };
  const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserMob(event.target.value);
  };
  const clearDate = () => {
    setStartDate('')
    setEndDate('')
}
  const importData = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate && endDate) {
        filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/backoffice/import/${selectVendor}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);

        //  response.data = decryptData(response.data)
        response.data = response.data
        const pages = 1;//response.data.payload.count / 10;
        // setTotalPages(Math.ceil(pages));
        // setPlData(response.data);
        // setLoading(false);
        getData();
      })
      .catch((error) => {

        console.log(error);
        setLoading(false)
      });
  }

  const filterUser = () => {
    setLoading(true);
    const filter: { _id?: string, name?: string, mobile?: string } = {};
    if (searchUserId) {
      filter._id = searchUserId
    }
    if (searchUserName) {
      filter.name = searchUserName
    }
    if (searchUserMob) {
      filter.mobile = searchUserMob
    }
    const token = localStorage.getItem("token");
    let data = {
      pageSize: 10,
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/backoffice/reports`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data)
        const pages = response.data.data.payload.count / 10;
        setTotalPages(Math.ceil(pages));
        setPlData(response.data.data.payload.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }
  const getData = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate && endDate) {
        filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/backoffice/reports`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
           response.data = decryptData(response.data)
        const pages = 1;//response.data.payload.count / 10;
        setTotalPages(Math.ceil(pages));
        setPlData(response.data);
        setLoading(false);
      })
      .catch((error) => {

        console.log(error);
        setLoading(false)
      });
  }
  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }
  const handleSelectVendor = (newValue: any) => {
    setSelectVendor(newValue)
  }


  const headings = [
    [
      "User Id",
      "Vendor Name",
      "Balance",
      "Bet Amount",
      "Total Profit",
      "Total Loss",
      "Withdrawal",
      "Deposit",
      "Final Profit",
      "Final Loss",
    ],
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"Profit & Loss"} />
              <div className="container-fluid">
                <div className="row">
                  <div className="row tp-form">
                    <div className="d-flex align-items-center mb-1">
                    <div className="container-fluid">
                            <div className="row tp-form">
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl">From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="From Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl">To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="To Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl"></label>
                                    <a className="sechBtn mt-1" onClick={getData}>
                                        Apply
                                    </a>
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl"></label>
                                    <a onClick={clearDate} className="sechBtn mt-1">
                                        Clear Dates
                                    </a>
                                </div>
                            </div>
                        </div>
                      {/* <div className="col-6 col-xl-1 col-sm-4 search-4">
                        <ExcelExport
                          inputData={plData}
                          headings={headings}
                          fileName={"Profit & Loss"}
                        />
                      </div>
                      <div>
                        <span className="ms-1">Download Data</span>
                      </div>*/}
                      <div className="col-6 col-xl-2 col-sm-4 pdrt">
                        <label className="lbl">Select Type</label>
                        <div className="mt-1">
                          <Stateful_Select
                            value={selectVendor}
                            onChange={(newValue: any) => handleSelectVendor(newValue)}
                            options={["qtech", "evolution"]}
                            className="deposit-select"
                            label="Select Status"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-xl-2 col-sm-3 pdrt">
                        <label className="lbl"></label>
                        <a className="sechBtn mt-1" onClick={importData}>
                          Import Data
                        </a>
                      </div>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-2">
                      <Stateful_Select
                        label="Items Per Page"
                        value={itemsPerPage.toString()}
                        onChange={(newValue: any) => handlePerPage(newValue)}
                        options={["10", "25", "50", "75", "100"]}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Vendor Name</th>
                            <th>Total Bet</th>
                            <th>Total Win</th>
                            <th>GGR</th>
                            <th>Dated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {plData.map((user, index) => {
                            // Calculation with fallbacks for undefined or null values
                            const totalBet = user.totalBet || 0;
                            const ourTotalBet = user.ourTotalBet || 0;
                            const totalWin = user.totalWin || 0;
                            let ourTotalWin = user.ourTotalWin || 0;
                            const ourTotalCommission = user.ourTotalCommission || 0;

                            ourTotalWin = ourTotalWin + ourTotalCommission;

                            const betDiff = totalBet - ourTotalBet;
                            const winDiff = totalWin - ourTotalWin;
                            const vendorGGR = totalBet - totalWin;
                            const ourGGR = ourTotalBet - ourTotalWin;
                            const ggrDiff = vendorGGR - ourGGR;

                            return (
                              <tr key={`${user.provider}-${user.reportDate}`}>
                                <td>
                                  {index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}
                                </td>
                                <td>{user.provider}</td>
                                <td>
                                  <div>Vendor Bet: {totalBet}</div>
                                  <div>Our Bet: {ourTotalBet}</div>
                                  <div>Diff Bet: {betDiff}</div>
                                </td>
                                <td>
                                  <div>Vendor Win: {totalWin}</div>
                                  <div>Our Win: {ourTotalWin}</div>
                                  <div>Diff Win: {winDiff}</div>
                                </td>
                                <td>
                                  <div>Vendor GGR: {vendorGGR}</div>
                                  <div>Our GGR: {ourGGR}</div>
                                  <div>Diff GGR: {ggrDiff}</div>
                                </td>
                                <td>{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(user.reportDate))}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}
